<template>
  <div>
    <b-row
      align-h="between"
      align-v="center"
      class="ml-1"
    >
      <b-col
        lg="2"
        md="3"
        sm="4"
        xs="12"
      >
        <h4>
          {{ routeName }}
        </h4>
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{ $t('common.perPage') }}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            :options="pageOptions"
            class="w-50"
            @change="updatePerPage"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
      >
        <b-form-group
          label-cols-sm="1"
          label-align-sm="right"
          label-for="filterInput"
          class="mr-1"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="search"
              type="search"
              :placeholder="$t('common.search')"
              class="mr-1"
              @keyup="searchItem"
            />

            <b-button
              v-if="$route.name !== 'drs-stocks'"
              variant="primary"
              @click="formSidebarButton(); $emit('onButtonClick')"
            >
              {{ buttonName }}
            </b-button>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="mx-2 mt-1">
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="0"
        :items="data"
        :fields="headers"
        :filter="filter"
        :filter-included-fields="filterOn"
        :empty-text="$t('invoice.no_result')"
        :busy="isBusy"
        show-empty
        :tbody-tr-class="rowClass"
        @filtered="onFiltered"
        @row-clicked="itemClick"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #cell(product)="product">
          <span v-if="product['item'].products != null">{{ product['item'].products }}</span>
          <span v-else>-</span>
        </template>

        <template #cell(actions)="actions">
          <!-- DIV for actions categories -->
          <div v-if="actions.item.products !== null">
            <b-button
              v-if="actions.item.products.length > 0  && $route.name === 'drs-categories'"
              :to="`/products/products?category=${actions.item.name}`"
              class="btn-icon"
              variant="info"
            >
              <feather-icon
                icon="ShoppingBagIcon"
                size="16"
                class="ml-0"
              />
            </b-button>
            <b-button
              v-if="actions.item.products.length <= 0"
              class="btn-icon"
              variant="danger"
              @click="deleteItem(actions.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="ml-0"
              />
            </b-button>
          </div>
          <div v-else>
            <b-button
              v-if="$route.name !== 'drs-categories'"
              class="btn-icon"
              variant="danger"
              @click="deleteItem(actions.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="ml-0"
              />
            </b-button>
          </div>
        </template>
      </b-table>
    </div>

    <b-card-body class="d-flex justify-content-between align-items-center flex-wrap pt-0">

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="settings.total"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mx-0"
          @change="page => $emit('changePage', page)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <!-- page length -->
      <span class="mx-0">{{ $t('common.displaying') }} {{ perPage * currentPage > settings.total ? settings.total : perPage * currentPage }} {{ $t('common.of') }} {{ settings.total }} {{ $t('common.registers') }}.</span>
    </b-card-body>

    <!-- Sidebar -->
    <content-sidebar-customer
      v-if="$route.name === 'drs-customers'"
      v-model="handlerSidebarShow"
      :header-title="buttonName"
      :edit-item="editItem"
      :is-editing="isEditing"
      :fill-address="onAddress"
      @onCreate="data => $emit('onCreate', data)"
      @onClose="handlerSidebarShow = false; isEditing = false"
      @onChangeAddress="data => $emit('changeAddressApi', data)"
    />

    <!-- Sidebar -->
    <content-sidebar
      v-else
      v-model="handlerSidebarShow"
      :header-title="buttonName"
      :field-flag="routeName"
      :edit-item="editItem"
      :is-editing="isEditing"
      @onCreate="data => $emit('onCreate', data)"
      @onUpdate="data => $emit('onUpdate', data)"
      @onClose="handlerSidebarShow = false; isEditing = false"
    />
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BCardBody,
  BSpinner,
  BButton,
  // BLink,
} from 'bootstrap-vue'
import ContentSidebar from './sidebar-form/ContentSidebarStock.vue'
import ContentSidebarCustomer from './sidebar-form/ContentSidebarCustomer.vue'

export default {
  name: 'BasicTable',
  components: {
    ContentSidebar,
    ContentSidebarCustomer,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BCardBody,
    BSpinner,
    BButton,
    // BLink,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    onAddress: {
      type: Object,
    },
    data: {
      type: Array,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    buttonTable: {
      type: String,
      default: '',
    },
    routeName: {
      type: String,
      default: '',
    },
    isBusy: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      headers: JSON.parse(JSON.stringify(this.fields)),
      buttonName: '',
      search: '',
      perPage: 50,
      pageOptions: [25, 50, 100, 500],
      totalRows: null,
      currentPage: 1,
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      handlerSidebarShow: false,
      editItem: {},
      isEditing: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeUpdate() {
    this.buttonName = `${this.$i18n.t(this.buttonTable)}`
    this.fieldLanguage()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
    this.buttonName = `${this.$i18n.t(this.buttonTable)}`
    this.fieldLanguage()
  },
  methods: {
    deleteItem(item) {
      this.$emit('deleteItem', item)
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (this.$route.name === 'drs-stocks') {
        // eslint-disable-next-line consistent-return
        if (item.units <= 0 || item.units < item.min_stock) return 'bg-warning bg-lighten-2'
      }
    },
    fieldLanguage() {
      this.fields.forEach((header, index) => {
        this.headers[index].label = `${this.$i18n.t(this.fields[index].label)}`
      })
    },
    formSidebarButton() {
      if (this.$route.name !== 'drs-customers') this.handlerSidebarShow = true
    },
    searchItem() {
      this.$emit('search', this.search)
    },
    itemClick(item) {
      this.$emit('onItemClick', item)
      if (this.$route.name !== 'drs-customers') {
        this.editItem = JSON.parse(JSON.stringify(item))
        this.isEditing = true
        this.handlerSidebarShow = true
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    updatePerPage(size) {
      this.$emit('onPerPage', size)
    },
  },
}
</script>
